import { Flex } from 'elcano';
import React from 'react';

export default function Custom404() {
  return (
    <Flex w={1} justifyContent="center">
      <h1>404 - Page Not Found</h1>
    </Flex>
  );
}
